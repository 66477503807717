import React, {useEffect, useState} from "react";
import {ImageItems, TextItems} from "../../models/FrontendModels";
import BackendService from "../BackendService/BackendService";
import EditComponentOverlay from "../Overlay/EditComponentOverlay";
import parse from "html-react-parser";
import Footer from "../footer/Footer";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Utils from "../BackendService/Utils";
import {AxiosError} from "axios";
import NavigationComponent from "../navigation/Navigation";
import {Button, Modal} from "react-bootstrap";
import RemoveImageOverlay from "../Overlay/RemoveImageOverlay";
import PopUp from "../PopUp/PopUp";
import CarouselComponent from "../Carousel";

const CategoryViewComponent = () => {
    const {categoryId} = useParams();
    let navigate = useNavigate()
    let location = useLocation();

    let [textItems, setTextItems]: [TextItems, any] = useState({})
    let [categoryName, setCategoryName]: [string, any] = useState("")
    let [imageItems, setImageItems]: [ImageItems, any] = useState({})
    let [partnerPlaceholders, setPartnerPlaceholders]: [string[], any] = useState([])
    let [imageUrls, setImageUrls]: [string[], any] = useState([])
    let [rows, setRows] : [number[], any] = useState([])
    const numberOfImagesInRow = 4

    let [showPopUp, setShowPopUp]: [boolean, any] = useState(false);
    let [showWarningPopUp, setShowWarningPopUp]: [boolean, any] = useState(false);
    let [imageNumberToRemove, setImageNumberToRemove]: [number, any] = useState(-1);
    let [carouselIndex, setCarouselIndex] = useState(0);

    useEffect(() => {
        BackendService.getTextByPlaceholder("category_overview_item_"+categoryId).then((res) => {
            setCategoryName(res.data)
        })
        BackendService.getTextsByPage(Utils.determinateDatabaseCategoryId(categoryId)).then((res) => {
                if (Object.keys(res.data).length == 0){
                    navigate("/404")
                }
                setTextItems(res.data);
            }
        ).catch(() => {
            navigate("/404")
        })
        BackendService.getImagesByPage(Utils.determinateDatabaseCategoryId(categoryId)).then((res) => {
            setImageItems(res.data)
            test(res.data)
            if (carouselIndex > Object.keys(res.data).length - 1) {
                window.location.reload();
            }
        }).catch((err: AxiosError) => {
            console.log("Images error: ", err)
        })
    }, [categoryId])

    useEffect(() => {
        let x: number[] = []
        for (let i = 1; i <= Math.ceil(partnerPlaceholders.length/numberOfImagesInRow); i++) {
            x.push(i)
            if(i + 1 > Math.ceil(partnerPlaceholders.length/numberOfImagesInRow)){
                setRows([...x])
            }
        }
    }, [partnerPlaceholders.length])

    function determinatePartnersImageIndexesByRow(numberOfRow: number) : number[] {
        let x: number[] = []
        for (let i = numberOfRow*numberOfImagesInRow-(numberOfImagesInRow-1); x.length != numberOfImagesInRow && i <= partnerPlaceholders.length ; i++) {
            x.push(i)
        }
        //console.log("Row", numberOfRow, "has images with indexes", x)
        return x
    }

    // Function to update carousel index
    const updateCarouselIndex = (newIndex: number) => {
        setCarouselIndex(newIndex);
    };

    function test(allImages: ImageItems) {
        let x : string[] = []
        let y : string[] = []
        for (let key in allImages){
            let placeholder = allImages[key].placeholder
            if(placeholder.includes("partner")){
                x.push(placeholder)
            }
            else{
                y.push(allImages[key].image)
            }
        }
        setPartnerPlaceholders([...x])
        setImageUrls([...y])
    }

    function removeImageOfPartner(imagePlaceholder: string){
        BackendService.removeImageByPlaceholder(imagePlaceholder).then(() => {
            setShowPopUp(true)
        }).catch((err) => {
            console.log("Error:", err)
        })
    }

    const WarningPopUp = () => {
        return (
            <Modal
                size="lg"
                centered
                show={showWarningPopUp}
                onHide={() => setShowWarningPopUp(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Zmazanie obrázku</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <a>Naozaj chcete zmazať tento obrázok?</a>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"outline-secondary"} autoFocus onClick={() => setShowWarningPopUp(false)}>Zrušiť</Button>
                    <Button variant={"danger"} onClick={() => {
                        setShowWarningPopUp(false)
                        removeImageOfPartner(partnerPlaceholders[imageNumberToRemove-1])
                    }}>Zmazať</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    if (Object.keys(textItems).length === 0 || Object.keys(imageItems).length === 0) {
        return (
            <></>
        )
    }
    return (
        <div>
            <NavigationComponent/>
            <div className="container" style={{marginTop: "9rem"}}>
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12" style={{textAlign: "left"}}>
                        <EditComponentOverlay placeholder={"category_overview_item_"+categoryId} component={
                            <div className="mb-3" style={{textAlign: "center"}}>
                                <h2><strong>{Utils.removeTagsFromRichString(categoryName)}</strong></h2>
                            </div>
                        }></EditComponentOverlay>
                        { textItems && textItems["category_item_" + categoryId + "_text"] &&
                            <EditComponentOverlay placeholder={"category_item_" + categoryId + "_text"} component={
                                <div>
                                    {parse(textItems["category_item_" + categoryId + "_text"]?.text ?? '')}
                                </div>
                            }></EditComponentOverlay>
                        }
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 mt-5 mt-md-0">
                        {Object.keys(imageUrls).length <= 1 ?
                            <div className="about-img position-relative px-4">
                                { imageItems && imageItems["category_" + categoryId] &&
                                    <EditComponentOverlay placeholder={"category_" + categoryId} imageComponent={true} placement={"top"} component={
                                        <div>
                                            <img src={Object.keys(imageItems).length === 0 ? "" : Utils.completePathToImage(imageItems["category_" + categoryId].image)}
                                                 alt="Product image"/>
                                        </div>
                                    }></EditComponentOverlay>
                                }
                            </div>
                            :
                            <div style={{padding: "1%"}}>
                                <CarouselComponent imageUrls={imageUrls} activeIndex={carouselIndex} onUpdateIndex={updateCarouselIndex} />
                            </div>
                        }
                        {Utils.UserIsLogged() && <Button className="mt-5" variant={"primary"} onClick={() => navigate("/categoryImagesEdit/" + categoryId + "/" + btoa(location.pathname))}>Upraviť obrázky</Button>}
                    </div>
                </div>
            </div>
            <div className="mt-5 mb-5">
                {partnerPlaceholders.length > 0 && <div className="container text-center">
                    <h2 className="fw-bold ls-0 mb-4" style={{fontSize: 46}}>
                        { textItems && textItems["category_item_"+categoryId+"_partners_headline"] &&
                            <EditComponentOverlay placeholder={"category_item_"+categoryId+"_partners_headline"} component={
                                <div>
                                    {Utils.removeTagsFromRichString(textItems["category_item_"+categoryId+"_partners_headline"].text)}
                                </div>
                            }></EditComponentOverlay>
                        }
                    </h2>
                    {
                        rows.map((rowNumber) => (
                        <div className="row justify-content-md-center mb-3">
                            {
                                determinatePartnersImageIndexesByRow(rowNumber).map((numberOfImage) => (
                                <div className="col-2">
                                    <div>
                                        <RemoveImageOverlay placeholder={partnerPlaceholders[numberOfImage-1]} onClick={() => {setImageNumberToRemove(numberOfImage); setShowWarningPopUp(true)}} placement={"bottom"} component={
                                            <EditComponentOverlay placeholder={partnerPlaceholders[numberOfImage-1]} imageComponent={true} placement={"top"} component={
                                                <div>
                                                    <img className={"partnerLogo"} src={Object.keys(imageItems).length === 0 ? "" : Utils.completePathToImage(imageItems[partnerPlaceholders[numberOfImage-1]].image)} alt="Logo"/>
                                                </div>
                                            }/>
                                        }/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>}
                {Utils.UserIsLogged() && <Button className="mt-5" variant={"success"} onClick={() => navigate("/imageupload/" + categoryId + "/" + btoa(location.pathname))}>Pridať partnera</Button>}
            </div>
            <Footer/>
            <PopUp TitleText={"Obrázok odstránený"} BodyText={"Obrázok bol úspešne odstránený."} show={showPopUp} onHide={() => window.location.reload()}></PopUp>
            <WarningPopUp/>
        </div>
    )
}

export default CategoryViewComponent;