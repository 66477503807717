import React, {useEffect, useState} from "react";
import BackendService, { Pages } from "../BackendService/BackendService";
import {MediaLinks, TextItems} from "../../models/FrontendModels";
import {AxiosError} from "axios";
import parse from 'html-react-parser';
import EditComponentOverlay from "../Overlay/EditComponentOverlay";
import Utils from "../BackendService/Utils";
import {isMobile} from 'react-device-detect';
import {Link} from "react-router-dom";

const FooterComponent = (props: {id?: string}) =>  {

    //Calling for media links & texts locally because of range usage of component
    let [textItems, setTextItems] : [TextItems, any] = useState({})
    let [mediaLinks, setMediaLinks] : [MediaLinks, any] = useState({})
    useEffect(() => {
        BackendService.getTextsByPage(Pages.Footer).then((textRes) =>{
                setTextItems(textRes.data);
            }
        ).catch((err: AxiosError) => {
            console.log("TEXTY ERROR!: ",err)
        })
        BackendService.getMediaLinks().then((res) =>{
                setMediaLinks(res.data);
            }
        ).catch((err: AxiosError) => {
            console.log("MEDIA ERROR!: ",err)
        })
    }, [])

    if(Object.keys(textItems).length === 0 || Object.keys(mediaLinks).length === 0){
        return (
            <></>
        )
    }
    else{
        return (
            <footer id={"footer"} className="dark">
                <div id={props.id} className="container">
                    <div className="footer-widgets-wrap">
                        <div className="row col-mb-50">
                            <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <div className="widget widget_links clearfix">
                                    <EditComponentOverlay placeholder={"footer_column_1_title"} placement={"top"} component={
                                        <h4 className={"footerTitle"}>{Utils.removeTagsFromRichString(textItems["footer_column_1_title"].text)}</h4>
                                    }></EditComponentOverlay>
                                    <ul>
                                        <li><Link className="bottommargin-5px" key={"1"} to="/#">Úvod</Link></li>
                                        <li><Link className="bottommargin-5px" key={"2"} to="/#oNas">O nás</Link></li>
                                        <li><Link className="bottommargin-5px" key={"3"} to="/#sluzby">Služby</Link></li>
                                        <li><Link className="bottommargin-5px" key={"4"} to="/#predajna">Predajňa</Link></li>
                                        <li><Link className="bottommargin-5px" key={"5"} to="/#produkty">Produkty</Link></li>
                                        <li><Link className="bottommargin-5px" key={"6"} to="/#kontakt">Kontakt</Link></li>
                                        <li><Link className="bottommargin-5px" key={"7"} to="/blog">Blog</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <div className="widget clearfix">
                                    <EditComponentOverlay placeholder={"footer_column_2_title"} placement={"top"} component={
                                        <h4 className={"footerTitle"}>{Utils.removeTagsFromRichString(textItems["footer_column_2_title"].text)}</h4>
                                    }></EditComponentOverlay>
                                    <div className="row justify-content-center">
                                        <div className={"col-lg-2 col-md-2 col-sm-2"}>
                                            <a target={"_blank"} href={mediaLinks["facebook"].link} className="social-icon si-dark si-colored si-delicious mb-0">
                                                <i className="icon-facebook"></i>
                                                <i className="icon-facebook"></i>
                                            </a>
                                        </div>
                                        <div className={"col-lg-5 col-md-4 col-sm-4"}>
                                            <a target={"_blank"} href={mediaLinks["facebook"].link}>
                                                <small>
                                                    <EditComponentOverlay placeholder={"facebook_description"} placement={"bottom"} component={
                                                        <div>
                                                            {parse(Utils.replaceParagraphs(textItems["facebook_description"].text))}
                                                        </div>
                                                    }></EditComponentOverlay>
                                                </small>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-4">
                                        <div className={"col-lg-2 col-md-2 col-sm-2"}>
                                            <a target={"_blank"} href={mediaLinks["instagram"].link} className="social-icon si-dark si-colored si-instagramCustomized mb-0">
                                                <i className="icon-instagram"></i>
                                                <i className="icon-instagram"></i>
                                            </a>
                                        </div>
                                        <div className={"col-lg-5 col-md-4 col-sm-4"}>
                                            <a target={"_blank"} href={mediaLinks["instagram"].link}>
                                                <small>
                                                    <EditComponentOverlay placeholder={"instagram_description"} placement={"bottom"} component={
                                                        <div>
                                                            {parse(Utils.replaceParagraphs(textItems["instagram_description"].text))}
                                                        </div>
                                                    }></EditComponentOverlay>
                                                </small>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-4">
                                        <div className={"col-lg-2 col-md-2 col-sm-2"}>
                                            <a target={"_blank"} href={mediaLinks["maps"].link} className="social-icon si-dark si-colored si-googleMapsCustomized mb-0">
                                                <i className="icon-map-marker2"></i>
                                                <i className="icon-map-marker2"></i>
                                            </a>
                                        </div>
                                        <div className={"col-lg-5 col-md-4 col-sm-4"}>
                                            <a target={"_blank"} href={mediaLinks["maps"].link}>
                                                <small>
                                                    <EditComponentOverlay placeholder={"maps_description"} placement={"bottom"} component={
                                                        <div>
                                                            {parse(Utils.replaceParagraphs(textItems["maps_description"].text))}
                                                        </div>
                                                    }></EditComponentOverlay>
                                                </small>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <div className="widget clearfix">
                                    <EditComponentOverlay placeholder={"footer_column_3_title"} placement={"top"} component={
                                        <h4 className={"footerTitle"}>{Utils.removeTagsFromRichString(textItems["footer_column_3_title"].text)}</h4>
                                    }></EditComponentOverlay>
                                    <div className={"row"}>
                                        <div className="clearfix bottommargin-mini" style={{marginLeft: "28px"}}>
                                        <a href={"tel: "+Utils.removeTagsFromRichString(textItems["phone"].text)} className="i-plain mb-0" >
                                            <i  className="icon-phone2"></i>
                                        </a>
                                            <a className="text-start" href={"tel:"+Utils.removeTagsFromRichString(textItems["phone"].text)} style={{display: "block", marginTop: "10px"}}>
                                                <EditComponentOverlay placeholder={"phone"} placement={"bottom"} component={
                                                    <div>
                                                        {parse(Utils.replaceParagraphs(textItems["phone"].text))}
                                                    </div>
                                                }></EditComponentOverlay>
                                            </a>
                                        </div>
                                    </div>

                                    <div className={"row mt-3"}>
                                        <div className="clearfix bottommargin-mini" style={{marginLeft: "28px"}}>
                                        <a target={"_blank"} href={"mailto: " + Utils.removeTagsFromRichString(textItems["email"].text)} className="i-plain mb-0" >
                                            <i className="icon-mail"></i>
                                        </a>
                                            <a className="text-start" target={"_blank"} href={"mailto: " + Utils.removeTagsFromRichString(textItems["email"].text)} style={{display: "block", marginTop: "10px"}}>
                                                <EditComponentOverlay placeholder={"email"} placement={"bottom"} component={
                                                    <div>
                                                        {parse(Utils.replaceParagraphs(textItems["email"].text))}
                                                    </div>
                                                }></EditComponentOverlay>
                                            </a>
                                        </div>
                                    </div>

                                    <div className={"row mt-3"}>
                                        <div className="clearfix bottommargin-mini" style={{marginLeft: "28px"}}>
                                            <a target={"_blank"} href={mediaLinks["maps"].link} className="i-plain mb-0">
                                                <i className="icon-home"></i>
                                            </a>
                                            <a className="text-start" target={"_blank"} href={mediaLinks["maps"].link} style={{display: "block", marginTop: "10px"}}>
                                                <EditComponentOverlay placeholder={"location"} placement={"bottom"} component={
                                                    <div>
                                                        {parse(Utils.replaceParagraphs(textItems["location"].text))}
                                                    </div>
                                                }></EditComponentOverlay>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <div className="widget clearfix">
                                    <EditComponentOverlay placeholder={"footer_column_4_title"} placement={"top"} component={
                                        <h4 className={"footerTitle"}>{Utils.removeTagsFromRichString(textItems["footer_column_4_title"].text)}</h4>
                                    }></EditComponentOverlay>
                                    <div className="row">
                                        <div className="clearfix bottommargin-mini">
                                            <div className="row">
                                                <EditComponentOverlay placeholder={"working_hours"} placement={"bottom"} component={
                                                    <div>
                                                        {parse(Utils.replaceParagraphs(textItems["working_hours"].text))}
                                                    </div>
                                                }></EditComponentOverlay>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="copyrights">
                    <div className="container">
                        <div className="row col-mb-30">
                            <div className="col-md-4 text-start">
                                Copyrights &copy; 2023 All Rights Reserved.&nbsp;
                                <span className=" copyright-links">
                                <a href="/login">Login</a>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default FooterComponent;