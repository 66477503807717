import React, {useEffect, useState} from 'react';
import {Button} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import BackendService from "../BackendService/BackendService";
import Utils from '../BackendService/Utils';
import PopUp from "../PopUp/PopUp";
import ImageUploadComponent from "../ImageUpload";

const ImageEditorComponent = () => {
    const { placeholder, encodedReturnUrl } = useParams();
    let [file, setFile] : [any, any] = useState('');
    let [isLoading, setIsLoading] : [boolean, any] = useState(true);
    let [isSending, setIsSending] : [boolean, any] = useState(false);
    let [showPopUp, setShowPopUp]: [boolean, any] = useState(false);
    let [returnUrl, setReturnUrl]: [string, any] = useState("");
    let navigate = useNavigate();

    const uploadProduct = async () => {
        setIsSending(true)
        BackendService.editImage(placeholder!, file).then((res) => {
            if(res.data == "OK"){
                setShowPopUp(true)
            }
            else{
                console.log(res.data)
                setIsSending(false)
            }
        }).catch((err) => {
            setIsSending(false)
            console.log(err)
        })
    }

    useEffect(() => {
        if(!Utils.UserIsLogged()){
            navigate("/401")
        }
        else if(placeholder == undefined){
            navigate("/404")
        }
        else {
            setIsLoading(false);
        }
        if(encodedReturnUrl == undefined)
            setReturnUrl("/")
        else
            setReturnUrl(atob(encodedReturnUrl))
    }, [])

    const handleFileChange = (e: any) => {
        const selectedFile = e.target.files[0];
        if (selectedFile &&  Utils.validateFileType(selectedFile)) {
            setFile(selectedFile);
        } else {
            setFile(null);
        }
    };

    if(isLoading){
        return(
            <div/>
        )
    }
    return (
        <div>
            <div className={"row justify-content-center mt-2"}>
                <div className={"col-6"}>
                    <h2>Zmena obrázku</h2>
                    <form className={"form-inline mt-3"}>
                        <ImageUploadComponent onChangeAction={handleFileChange}/>
                        <div className={"mt-4"}>
                            <Button variant={"outline-danger"} onClick={() => navigate(returnUrl)}>Zahodiť</Button>
                            <Button variant={"success"} onClick={uploadProduct} disabled={isSending} style={{marginLeft: "10px"}}>{isSending ? "Ukladám" : "Uložiť"}</Button>
                        </div>
                    </form>
                </div>
            </div>
            <PopUp TitleText={"Obrázok zmenený"} BodyText={"Obrázok bol úspešne zmenený."} show={showPopUp} onHide={() => navigate(returnUrl)}></PopUp>
        </div>
    );
}

export default ImageEditorComponent;