import React, {useEffect, useState} from "react";
import HomeSliderComponent from "./homeSlider/HomeSlider";
import {ImageItems, MediaLinks, Settings, TextItems} from "../../models/FrontendModels";
import NavigationComponent from "../navigation/Navigation";
import Footer from "../footer/Footer";
import ContactComponent from "./Sections/Contact";
import ServicesComponent from "./Sections/Services";
import AboutUsComponent from "./Sections/AboutUs";
import InstagramFeedComponent from "./Sections/InstagramFeed";
import ShopComponent from "./Sections/Shop";
import BackendService, {Pages} from "../BackendService/BackendService";
import {AxiosError} from "axios";
import ProductCategoriesComponent from "./Sections/ProductCategories/ProductCategories";
import BlogsCarouselShowcaseComponent from "../Blogs/BlogsCarouselShowcase";

const HomeComponent = () => {
    let [textItems, setTextItems] : [TextItems, any] = useState({})
    let [imageItems, setImageItems] : [ImageItems, any] = useState({})
    let [mediaLinks, setMediaLinks] : [MediaLinks, any] = useState({})
    let [settings, setSettings] : [Settings, any] = useState({})

    useEffect(() => {
        BackendService.getTextsByPage(Pages.Home).then((res) => {
            setTextItems(res.data);
        }).catch((err: AxiosError) => {
            console.log("Texts homepage error: ", err)
        })
        BackendService.getImagesByPage(Pages.Home).then((res) => {
            setImageItems(res.data)
        }).catch((err: AxiosError) => {
            console.log("Images homepage error: ", err)
        })
        BackendService.getMediaLinks().then((res) => {
            setMediaLinks(res.data)
        }).catch((err: AxiosError) => {
            console.log("MediaLinks homepage error: ", err)
        })
        BackendService.getSettings().then((res) => {
            setSettings(res.data)
        }).catch((err: AxiosError) => {
            console.log("Settings homepage error: ", err)
        })
    }, [])

    return (
            <div>
                <NavigationComponent settings={settings}/>
                <HomeSliderComponent settings={settings}/>
                {(Object.keys(textItems).length === 0 || Object.keys(imageItems).length === 0) ? <></> : <>
                    <AboutUsComponent id={"oNas"} texts={textItems} images={imageItems}/>
                    <ServicesComponent id={"sluzby"} texts={textItems} images={imageItems}/>
                    {Object.keys(mediaLinks).length === 0 ? <></> : <ShopComponent id={"predajna"} mediaLinks={mediaLinks} texts={textItems} images={imageItems}/>}
                    <ProductCategoriesComponent id={"produkty"} texts={textItems} images={imageItems}/>
                    <ContactComponent id={"formular"} texts={textItems} images={imageItems}/>
                    <BlogsCarouselShowcaseComponent/>
                </>}
                <Footer id={"kontakt"}/>
            </div>
    );
}

export default HomeComponent;