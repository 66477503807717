export class Constants{

    public static get defaultMeta(): any{
        return {
            title: 'ML Baby Malacky',
            description: 'Obchod s detským oblečením ML Baby Malacky',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'ML Baby,obchod,Malacky,oblečenie,detské,deti,kočíky,hračky'
                }
            }
        }
    }
}