import axios, { AxiosResponse } from "axios";
import {MediaLinks, Settings} from "../../models/FrontendModels";

class BackendService {

    // region DECLARATIONS

    static config = require("./config.json");
    static serverUrl = this.config.serverUrl;

    // endregion

    static getMediaLinks(): Promise<AxiosResponse>{
        return axios.get<MediaLinks>(this.serverUrl+"mediaLinksGet.php");
    }

    static getSettings(): Promise<AxiosResponse>{
        return axios.get<Settings>(this.serverUrl+"settingsGet.php");
    }

    static getLatestInstagramPosts(): Promise<AxiosResponse>{
        return axios.get(this.serverUrl+"instagramFeed.php");
    }

    static getTextByPlaceholder(placeholder: string): Promise<AxiosResponse>{
        return axios.get(this.serverUrl+"textGetByPlaceholder.php", {
            params: {
                placeholder: placeholder
            }
        });
    }

    static getImageByPlaceholder(placeholder: string): Promise<AxiosResponse>{
        return axios.get(this.serverUrl+"imageGetByPlaceholder.php", {
            params: {
                placeholder: placeholder
            }
        });
    }

    static getTextsByPage(pageId: number): Promise<AxiosResponse>{
        return axios.get(this.serverUrl+"textsGetByPage.php", {
            params: {
                pageId: pageId
            }
        });
    }

    static getImagesByPage(pageId: number): Promise<AxiosResponse>{
        return axios.get(this.serverUrl+"imagesGetByPage.php", {
            params: {
                pageId: pageId
            }
        });
    }

    static updateText(placeholder: string, text: string): Promise<AxiosResponse>{
        const params = new URLSearchParams();
        params.append('placeholder', placeholder);
        params.append('text', text);
        return axios.post(this.serverUrl+"textUpdate.php", params);
    }

    static updateMediaLinks(facebookLink: string, instagramLink: string, googleMapsLink: string, email: string): Promise<AxiosResponse>{
        const params = new URLSearchParams();
        params.append('facebookLink', facebookLink);
        params.append('instagramLink', instagramLink);
        params.append('googleMapsLink', googleMapsLink);
        params.append('email', email);
        return axios.post(this.serverUrl+"mediaLinksUpdate.php", params);
    }

    static updateSettings(showHomepageInfo: boolean, navbarLogoWithText: boolean): Promise<AxiosResponse>{
        const params = new URLSearchParams();
        params.append('showHomepageInfo', showHomepageInfo ? "1" : "0");
        params.append('navbarLogoWithText', navbarLogoWithText ? "1" : "0");
        return axios.post(this.serverUrl+"settingsUpdate.php", params);
    }

    static login(userName: string, password: string): Promise<AxiosResponse>{
        const params = new URLSearchParams();
        params.append('userName', userName);
        params.append('password', password);
        return axios.post(this.serverUrl+"login.php", params);
    }

    static userCheck(): Promise<AxiosResponse>{
        return axios.get(this.serverUrl+"userCheck.php");
    }

    static logout(): Promise<AxiosResponse>{
        return axios.post(this.serverUrl+"logout.php");
    }

    static sendEmail(name: string, email: string, message: string): Promise<AxiosResponse>{
        const params = new URLSearchParams();
        params.append('name', name);
        params.append('email', email);
        params.append('message', message);
        return axios.post(this.serverUrl+"sendEmail.php", params);
    }

    static uploadImage(pageId: number, isPartner: boolean, file: any,): Promise<AxiosResponse>{
        const formData = new FormData();
        formData.append("pageId", pageId.toString())
        formData.append('isPartner', isPartner ? "1" : "0");
        formData.append("file", file)
        return axios.post(this.serverUrl+"imageUpload.php", formData, {
            headers:{'Content-Type':"multipart/form-data"},
        });
    }

    static editImage(placeholder: string, file: any): Promise<AxiosResponse>{
        const formData = new FormData();
        formData.append("placeholder", placeholder!)
        formData.append("file", file)
        return axios.post(this.serverUrl+"image.php", formData, {
            headers:{'Content-Type':"multipart/form-data"},
        });
    }

    static removeImageByPlaceholder(placeholder: string): Promise<AxiosResponse>{
        return axios.delete(this.serverUrl+"image.php", {
            params: {
                placeholder: placeholder
            }
        });
    }

    static getAllBlogs(): Promise<AxiosResponse>{
        return axios.get(this.serverUrl+"blogs.php");
    }

    static getBlogPost(id: number): Promise<AxiosResponse>{
        return axios.get(this.serverUrl+"blogs.php", {
            params: {
                id: id
            }
        });
    }

    static createBlogPost(title: string, description: string, text: string): Promise<AxiosResponse>{
        const params = new URLSearchParams();
        params.append('title', title);
        params.append('description', description);
        params.append('text', text);
        return axios.post(this.serverUrl+"blogs.php", params);
    }

    static editBlogPost(id: number, title: string, description: string, text: string): Promise<AxiosResponse>{
        const params = new URLSearchParams();
        params.append('id', id.toString());
        params.append('title', title);
        params.append('description', description);
        params.append('text', text);
        return axios.post(this.serverUrl+"blogs.php", params);
    }

    static deleteBlogPost(id: string): Promise<AxiosResponse>{
        return axios.delete(this.serverUrl+"blogs.php", {
            params: {
                id: id
            }
        });
    }

    static getBlogImagesByBlogId(blogId: number): Promise<AxiosResponse>{
        return axios.get(this.serverUrl+"imageBlog.php", {
            params: {
                blogId: blogId
            }
        });
    }

    static uploadBlogImage(blogId: string, file: any): Promise<AxiosResponse>{
        const formData = new FormData();
        formData.append("blogId", blogId)
        formData.append("file", file)
        formData.append("edit", "0")
        return axios.post(this.serverUrl+"imageBlog.php", formData, {
            headers:{'Content-Type':"multipart/form-data"},
        });
    }

    static editBlogImage(blogId: string, file: any): Promise<AxiosResponse>{
        const formData = new FormData();
        formData.append("blogId", blogId.toString())
        formData.append("file", file)
        formData.append("edit", "1")
        return axios.post(this.serverUrl+"imageBlog.php", formData, {
            headers:{'Content-Type':"multipart/form-data"},
        });
    }

    static removeBlogImageByImageId(imageId: number): Promise<AxiosResponse>{
        return axios.delete(this.serverUrl+"imageBlog.php", {
            params: {
                imageId: imageId.toString()
            }
        });
    }
}

export enum Pages{
    _ = 0,
    Home = 1,
    Footer = 2,
    CategoryItem1 = 3,
    CategoryItem2 = 4,
    CategoryItem3 = 5,
    CategoryItem4 = 6,
    CategoryItem5 = 7,
    CategoryItem6 = 8,
    CategoryItem7 = 9,
    CategoryItem8 = 10,
    CategoryItem9 = 11,
}
export default BackendService;