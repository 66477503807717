import React, {ChangeEvent, useEffect, useState} from 'react';
import {Button} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import BackendService from "../BackendService/BackendService";
import Utils from '../BackendService/Utils';
import PopUp from "../PopUp/PopUp";
import {AxiosError} from "axios/index";
import {BlogImages} from "../../models/FrontendModels";

const BlogImagesEditorComponent = () => {
    const { blogId } = useParams();
    const [files, setFiles] = useState<File[]>([]);
    let [isLoading, setIsLoading] : [boolean, any] = useState(true);
    let [isSending, setIsSending] : [boolean, any] = useState(false);
    let [showPopUp, setShowPopUp]: [boolean, any] = useState(false);
    let [images, setImages] : [BlogImages, any] = useState({});
    let navigate = useNavigate();

    const uploadImages = async () => {
        setIsSending(true);
        let error = false
        const uploadPromises = files.map(async (file) => {
            try {
                const res = await BackendService.uploadBlogImage(blogId!, file);
                console.log(res.data);
                if (res.data !== "OK") {
                    console.log(res.data);
                    error = true;
                }
            } catch (err) {
                console.error(err);
                error  = true
            }
        });

        // Wait for all upload promises to resolve
        await Promise.all(uploadPromises);

        if (error){
            setIsSending(false);
        }
        else {
            if (Object.keys(files).length > 0){
                setShowPopUp(true);
            }
            else {
                navigate("/blog/"+blogId);
            }
        }
    }

    useEffect(() => {
        if (!Utils.UserIsLogged()) {
            navigate("/401")
        } else if (blogId == undefined || isNaN(Number(blogId))) {
            navigate("/404")
        } else {
            BackendService.getBlogImagesByBlogId(Number(blogId!)).then((res) => {
                if (res.data == "BLOG POST DOES NOT EXIST")
                    navigate("/404")
                else {
                    setImages(res.data)
                    setIsLoading(false)
                }
            }).catch((err: AxiosError) => {
                console.log("Images error: ", err)
                setIsLoading(false)
            })
        }
    }, [])


    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const selectedFiles: File[] = Array.from(e.target.files); // Convert FileList to an array of File
            setFiles(selectedFiles); // Set the state with the array of selected files
        }
    };

    function deleteImage(imageId: number) {
        BackendService.removeBlogImageByImageId(imageId).then(() => {
            window.location.reload()
        }).catch((err) => {
            console.log(err)
        })
    }

    if(isLoading){
        return(
            <div/>
        )
    }
    return (
        <div>
            <div className={"row justify-content-center mt-2"}>
                <h2>Upraviť obrázky</h2>
                {Object.keys(images).length == 0 ?
                    <h1>Nenašli sa žiadne obrázky</h1>
                    :
                    <table style={{width: 800}}>
                        <tbody>
                        {Object.keys(images).map((imgId) => {
                            let imageId = Number(imgId)
                            return (
                                <div className={"row justify-content-center mt-2"}>
                                    <div className={"col-6"}>
                                        <td><img style={{maxWidth: "200px", maxHeight: "200px"}}
                                                 src={Utils.completePathToImage(images[imageId].image)}
                                                 alt="BLog image"/></td>
                                    </div>
                                    <div className={"col-6 mt-2"}>
                                        <td><Button style={{margin: "130%", padding: "30%"}} onClick={() => deleteImage(imageId)} variant={"danger"}>Zmazať</Button></td>
                                    </div>
                                </div>
                            );
                        })}
                        </tbody>
                    </table>
                }

                <div className={"col-6"}>
                    <h2>Pridať obrázky</h2>
                    <form className={"form-inline mt-3"}>
                        <div className={"form-group"}>
                            <label style={{ display: 'flex', justifyContent: 'start' }}>Zvoľte obrázky:</label>
                            <input
                                required={true}
                                type={"file"}
                                accept={"image/*"}
                                className={"form-control"}
                                onChange={handleFileChange}
                                multiple
                            />
                        </div>
                    </form>
                </div>

                <div className={"mt-2"}>
                    <Button variant={"outline-danger"} onClick={() => navigate("/blog/"+blogId)}>Zahodiť</Button>
                    <Button variant={"success"} onClick={uploadImages} disabled={isSending}
                            style={{marginLeft: "10px"}}>{isSending ? "Ukladám" : "Uložiť"}</Button>
                </div>
            </div>

            <PopUp TitleText={"Zmeny vykonané"} BodyText={"Všetky zmeny boli úspešne vykonané."} show={showPopUp}
                   onHide={() => navigate("/blog/"+blogId)}></PopUp>
        </div>
    );
}

export default BlogImagesEditorComponent;