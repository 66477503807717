import React, {useEffect, useState} from 'react';
import {Button} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import BackendService from "../BackendService/BackendService";
import Utils from '../BackendService/Utils';
import PopUp from "../PopUp/PopUp";
import {AxiosError} from "axios";
import {ImageItems} from "../../models/FrontendModels";
import ImageUploadComponent from "../ImageUpload";

const CategoryImagesEditComponent = () => {
    const {categoryId, encodedReturnUrl} = useParams();
    let [file, setFile]: [any, any] = useState('');
    let [isLoading, setIsLoading]: [boolean, any] = useState(true);
    let [isSending, setIsSending]: [boolean, any] = useState(false);
    let [showPopUp, setShowPopUp]: [boolean, any] = useState(false);
    let [returnUrl, setReturnUrl]: [string, any] = useState("");
    let [imgUrls, setImgUrls]: [string[], any] = useState([])
    let [imgPlaceholders, setImgPlaceholders]: [string[], any] = useState([])
    let navigate = useNavigate();

    const uploadProduct = async () => {
        setIsSending(true)
        BackendService.uploadImage(Utils.determinateDatabaseCategoryId(categoryId), false, file).then((res) => {
            if (res.data == "OK") {
                setShowPopUp(true)
            } else {
                console.log(res.data)
                setIsSending(false)
            }
        }).catch((err) => {
            setIsSending(false)
            console.log(err)
        })
    }

    function checkIfImageIsReadyToUpload() {
        if(file == '')
            navigate(returnUrl)
        else
            uploadProduct()
    }

    useEffect(() => {
        if (!Utils.UserIsLogged()) {
            navigate("/401")
        } else if (categoryId == undefined || Utils.determinateDatabaseCategoryId(categoryId) == -1) {
            navigate("/404")
        } else {
            if (encodedReturnUrl == undefined)
                setReturnUrl("/")
            else
                setReturnUrl(atob(encodedReturnUrl))
            BackendService.getImagesByPage(Utils.determinateDatabaseCategoryId(categoryId)).then((res) => {
                determinateMainImagesUrl(res.data)
                setIsLoading(false)
            }).catch((err: AxiosError) => {
                console.log("Images error: ", err)
                setIsLoading(false)
            })
        }

    }, [])

    function deleteImage(placeholder: string) {
        BackendService.removeImageByPlaceholder(placeholder).then(() => {
            window.location.reload()
        }).catch((err) => {
            console.log(err)
        })
    }

    function determinateMainImagesUrl(allImages: ImageItems) {
        let tmpImageUrls: string[] = []
        let tmpImgPlaceholders: string[] = []
        for (let key in allImages) {
            let placeholder = allImages[key].placeholder
            if (!placeholder.includes("partner")) {
                tmpImageUrls.push(allImages[key].image)
                tmpImgPlaceholders.push(allImages[key].placeholder)
            }
        }
        setImgUrls([...tmpImageUrls])
        setImgPlaceholders([...tmpImgPlaceholders])
    }

    const handleFileChange = (e: any) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && Utils.validateFileType(selectedFile)) {
            setFile(selectedFile);
        } else {
            setFile(null);
        }
    };

    if (isLoading) {
        return (
            <div/>
        )
    }
    return (
        <div>
            <div className={"row justify-content-center mt-2"}>
                <h2>Upraviť obrázky</h2>
                {imgUrls.length == 0 ?
                    <h1>Nenašli sa žiadne obrázky</h1>
                    :
                    <table style={{width: 800}}>
                        <tbody>
                        {imgPlaceholders.map((imgPlaceholder) => {
                            return (
                                <div className={"row justify-content-center mt-2"}>
                                    <div className={"col-6"}>
                                        <td><img style={{maxWidth: "200px", maxHeight: "200px"}}
                                                 src={Utils.completePathToImage(imgUrls[imgPlaceholders.indexOf(imgPlaceholder)])}
                                                 alt="Product image"/></td>

                                    </div>
                                    <div className={"col-6 mt-2"}>
                                        {
                                            imgPlaceholder == "category_"+categoryId
                                                ? <td><Button style={{margin: "130%", padding: "30%"}} onClick={() => navigate("/imageEdit/category_" + categoryId + "/" + encodedReturnUrl)} variant={"primary"}>Upraviť</Button></td>
                                                : <td><Button style={{margin: "130%", padding: "30%"}} onClick={() => deleteImage(imgPlaceholder)} variant={"danger"}>Zmazať</Button></td>
                                        }
                                    </div>
                                </div>
                            );
                        })}
                        </tbody>
                    </table>
                }
            </div>
            <div className={"row justify-content-center mt-2"}>

                <div className={"col-6"}>
                    <h2>Pridať obrázok</h2>
                    <form className={"form-inline mt-3"}>
                        <ImageUploadComponent onChangeAction={handleFileChange}/>
                        <div className={"mt-4"}>
                            <Button variant={"outline-danger"} onClick={() => navigate(returnUrl)}>Zahodiť</Button>
                            <Button variant={"success"} onClick={() => checkIfImageIsReadyToUpload()} disabled={isSending}
                                    style={{marginLeft: "10px"}}>{isSending ? "Ukladám" : "Uložiť"}</Button>
                        </div>
                    </form>
                </div>
            </div>
            <PopUp TitleText={"Obrázok pridaný"} BodyText={"Obrázok bol úspešne pridaný."} show={showPopUp}
                   onHide={() => navigate(returnUrl)}></PopUp>
        </div>
    );
}

export default CategoryImagesEditComponent;