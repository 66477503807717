import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {NavigationSubMenuItem, Settings, TextItems} from "../../models/FrontendModels";
import BackendService, { Pages } from "../BackendService/BackendService";
import Utils from "../BackendService/Utils";
import {Container, Nav, Navbar} from "react-bootstrap";

const NavigationComponent = (props: { settings?: Settings }) => {
    let location = useLocation();
    let scrollToElementDelay = 500; // milliseconds
    let backgroundNavbarListColor = 'rgba(70,70,70,0.9)';

    useEffect(()=>{
        if(pageIsHomePage()){
            if(window.location.href.includes("#") && !window.location.href.endsWith("#")){
                scrollToElement()
            }
        }
    }, [location]);

    useEffect(() => {
        //@ts-ignore
        window['init']();
    }, [location.pathname]);

    function scrollToElement(){
        let id = window.location.href.substr(window.location.href.indexOf("#")).replace("#", "")
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView();
        }
        else {
            setTimeout(() => scrollToElement(), scrollToElementDelay);
        }
    }

    let [textItems, setTextItems] : [TextItems, any] = useState({})
    useEffect(() => {
        BackendService.getTextsByPage(Pages.Home).then((res) =>{
            setTextItems(res.data);
        }
        ).catch((err) => {
            console.log("ERROR!: ",err)
        })
    }, [])

    function pageIsHomePage() : boolean  {
        return location.pathname == '/'
    }

    let logoWithText = props.settings?.["navbar_logo_with_text"]?.boolValue && pageIsHomePage();
    let logo : JSX.Element = (
        <div id="logo">
            <img src={"https://mlbaby.sk/img/logo/mlbaby_with"+(logoWithText?"":"out")+"_text.png"}
                                   alt={"MlBaby logo"} />
        </div>)

    let navMenuItems : NavigationSubMenuItem[] = [
        {displayName: "Úvod", linkPath: "/"},
        {displayName: "O nás", linkPath: "/#oNas"},
        {displayName: "Služby", linkPath: "/#sluzby", children: [
                1,2,3,4
            ].map(serviceNumber => {
                return {
                    displayName: Utils.removeTagsFromRichString(textItems["services_card_" + serviceNumber + "_headline"]?.text),
                    linkPath: "/#sluzby"
                }
            } )
        },
        {displayName: "Predajňa", linkPath: "/#predajna"},
        {displayName: "Produkty", linkPath: "/#produkty", children: [
                1,2,3,4,5,6,7
            ].map(categoryNumber => {
                return {
                    displayName: Utils.removeTagsFromRichString(textItems["category_overview_item_"+categoryNumber]?.text),
                    linkPath: "/kategoria/" + categoryNumber
                }
            } )
        },
        {displayName: "Kontakt", linkPath: "/#kontakt"},
        {displayName: "Blog", linkPath: "/blog"},
    ]

        return(
            <header id="header" className={"full-header " + (pageIsHomePage() ? "transparent-header " : "") + "no-sticky dark"}>
                <Navbar expand="xl" data-bs-theme="dark" className={"p-0 m-0"}>
                    <Container className={"p-0 m-0 me-2"} style={{maxWidth: "none"}}>
                        <Link to="/" className={"navbar-brand"}>
                            {logo}
                        </Link>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav"  style={{flexGrow: "unset"}}>
                            <Nav className="primary-menu">
                                {navMenuItems.map((item, index) => (
                                    <div className={"menu-item pe-3"}>
                                        <Link className={"nav-link menu-link"} to={item.linkPath} style={{fontSize: "13px"}} key={item.linkPath+index}>
                                            {item.displayName}
                                        </Link>
                                        {item.children && (
                                            <ul className="sub-menu-container"
                                                style={ {
                                                    backgroundColor: pageIsHomePage() ?backgroundNavbarListColor : undefined,
                                                    width: "fit-content",
                                                    blockSize: "fit-content",
                                                    whiteSpace: "nowrap"
                                            }}
                                            >
                                                {
                                                    item.children.map((child, index) => (
                                                        <li className="menu-item" key={item.linkPath+index}>
                                                            <Link className="nav-link menu-link" to={child.linkPath} style={{fontSize: "13px"}}>
                                                                <div style={{color: 'rgb(255,255,255)'}}>{child.displayName}</div>
                                                            </Link>
                                                        </li>))
                                                }
                                            </ul>)
                                        }
                                    </div>
                                ))}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>

        )
}

export default NavigationComponent;