import React, {useEffect} from "react";
import {Button, Form} from "react-bootstrap";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import BackendService from "../BackendService/BackendService";
import Utils from "../BackendService/Utils";
import PopUp from "../PopUp/PopUp";
import {Settings} from "../../models/FrontendModels";
import {AxiosError} from "axios";
import {Checkbox, FormControlLabel} from "@material-ui/core";

const SettingsComponent = () => {
    let navigate = useNavigate();
    let [settings, setSettings] : [Settings, any] = useState({});
    let [showHomepageInfo, setShowHomepageInfo] : [boolean, any] = useState(false);
    let [navbarLogoWithText, setNavbarLogoWithText] : [boolean, any] = useState(false);
    let [isSending, setIsSending] : [boolean, any] = useState(false);
    let [isUpdated, setIsUpdated] : [boolean, any] = useState(false);
    let [updateError, setUpdateError]: [boolean, any] = useState(false);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setIsSending(true)
        BackendService.updateSettings(showHomepageInfo, navbarLogoWithText).then((res) => {
            setIsUpdated(true)
        }).catch((err : AxiosError) => {
            setUpdateError(true)
            setIsSending(false)
            console.log(err)
        })
    }

    useEffect(() => {
        if(!Utils.UserIsLogged()){
            navigate("/401")
        }
        else{
            BackendService.getSettings().then((res) => {
                setSettings(res.data)
            }).catch((err : AxiosError) => {
                console.log(err)
            })
        }
    }, [])

    useEffect(() => {
        if(Object.keys(settings).length !== 0 ){
            setShowHomepageInfo(settings["show_homepage_info"].boolValue)
            setNavbarLogoWithText(settings["navbar_logo_with_text"].boolValue)
        }
    }, [settings])

    if(!Utils.UserIsLogged()){
        return(
            <div/>
        )
    }
    else{
        return (
            <div className={"container-fluid"} style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '105vh'}}>
                <Form onSubmit={handleSubmit}>
                    <h1 className={"mb-3"}>Upraviť nastavenia</h1>
                    <h4 style={{marginBottom: "-20px"}}>Homepage:</h4><br/>
                    <FormControlLabel id={"1"} control={<Checkbox color={"primary"} checked={showHomepageInfo} onChange={event => {setShowHomepageInfo(event.target.checked)}}/>} label={"Zobraziť prvky na obrázku"} style={{marginBottom: "0px"}}/><br/>
                    <FormControlLabel id={"2"} control={<Checkbox color={"primary"} checked={navbarLogoWithText} onChange={event => {setNavbarLogoWithText(event.target.checked)}}/>} label={"Použiť logo s textom"}/><br/>
                    <Button className={"mb-4"} style={{width: 200}} variant={"outline-info"} onClick={() => navigate("/imageEdit/title")}>Vybrať nový obrázok</Button><br/>
                    <div className={"row mt-2"} style={{display: 'flex', justifyContent:'center'}}>
                        <Button style={{width: 100}} variant={"outline-danger"} onClick={() => navigate("/admin")}>Zrušiť</Button>
                        <Button style={{width: 100, marginLeft: 25}} type={"submit"} disabled={isSending || isUpdated} variant={"success"}>{isSending ? "Ukladám" : "Uložiť"}</Button>
                    </div>
                </Form>
                <PopUp TitleText={"Upravené :)"} BodyText={"Nastavenia boli úspěsne upravené."} show={isUpdated} onHide={() => navigate("/admin")}></PopUp>
                <PopUp TitleText={"Nastala chyba"} BodyText={"Nastala nečakaná chyba. Skúste opakovať akciu znovu. Pri opakovanej chybe kontaktuje správcu webu."} show={updateError} onHide={() => setUpdateError(false)}></PopUp>
            </div>
        )
    }
}

export default SettingsComponent;