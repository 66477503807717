import React, {useEffect, useState} from "react";
import BackendService from "../BackendService/BackendService";
import {AxiosError} from "axios/index";
import {BlogsBasicData} from "../../models/FrontendModels";
import './Blogs.css';
import {Carousel} from '@trendyol-js/react-carousel';
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

const BlogsCarouselShowcaseComponent = () => {
    let navigate = useNavigate();
    let [blogs, setBlogs]: [BlogsBasicData, any] = useState({});
    useEffect(() => {
        BackendService.getAllBlogs().then((res) => {
            setBlogs(res.data)
        }).catch((err: AxiosError) => {
            console.log(err)
        })
    }, [])

    return (
        <div className={"row mb-0 "} style={{backgroundColor: "rgba(250,250,250,0.98)", width: "100%"}}>
            <h4 className={"pb-5 pt-4"}>Blog</h4>
            {blogs && Object.keys(blogs).length > 0 ? (
                <Carousel
                    show={3.5}
                    slide={1}
                    swiping={true}
                    infinite={true}
                    hideArrows={false}
                    autoSwipe={5000}
                    leftArrow={<Button style={{marginTop: "100px", marginRight: "15px", marginLeft: "10px"}} variant={"light"}>{"<"}</Button>}
                    rightArrow={<Button style={{marginTop: "100px", marginLeft: "15px", marginRight: "10px"}} variant={"light"}>{">"}</Button>}
                >
                    {Object.keys(blogs)
                        .reverse()
                        .map(key => (
                            <div className="item" style={{width: "350px"}}
                                 onClick={() => navigate("/blog/" + blogs[key].id)}
                                 key={blogs[key].id}>
                                <div className="item-in" style={{textAlign: 'left'}}>
                                    <h4>{blogs[key].title}</h4>
                                    <div className="seperator"></div>
                                    <p>{blogs[key].description}</p>
                                    <a className={"link"}>Prečítať</a>&nbsp;<p className={"arrowIcon"}>➜</p>
                                </div>
                            </div>
                        ))
                    }
                </Carousel>
            ) : (
                <p>Načítavam...</p>
            )}
            <div className={"row justify-content-center m-0 p-3"}>
                <Button variant={"dark"} className={"col-2"} onClick={() => navigate("/blog")}>Zobraziť viac</Button>
            </div>
        </div>
    );
}

export default BlogsCarouselShowcaseComponent;