import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Utils from '../BackendService/Utils';
import {Placement} from "react-bootstrap/types";

const RemoveImageOverlayTriggerComponent = (props: {placeholder: string, component: any, onClick: any, placement?: Placement, disableBorderOnHover?: boolean}) => {

    if(!Utils.UserIsLogged()){
        return(
            <>
                {props.component}
            </>
        )
    }
    else{
        return(
            <OverlayTrigger
                placement={(props.placement == null) ? "right" : props.placement}
                delay={{show: 250, hide: 1000 }}
                overlay={
                    <Tooltip id="button-tooltip">
                        {
                            <Button variant={"danger"} onClick={props.onClick}>Odstrániť obrázok</Button>
                        }
                    </Tooltip>
                }
            >
                <div className={props.disableBorderOnHover ? "" : "editableText"}>
                    {props.component}
                </div>
            </OverlayTrigger>
        )
    }
}

export default RemoveImageOverlayTriggerComponent;
