import React, {useState} from "react";
import {Button, Form} from "react-bootstrap";
import CaptchaPopUp from "../../PopUp/CaptchaPopUp";
import BackendService from "../../BackendService/BackendService";
import PopUp from "../../PopUp/PopUp";
import EditComponentOverlay from "../../Overlay/EditComponentOverlay";
import parse from 'html-react-parser';
import {ImageItems, TextItems} from "../../../models/FrontendModels";

const ContactComponent = (props: {id: string, texts: TextItems, images: ImageItems }) => {

    let [name, setName]: [string, any] = useState("");
    let [email, setEmail]: [string, any] = useState("");
    let [message, setMessage]: [string, any] = useState("");
    let [captchaPopUpOpen, setCaptchaPopUpOpen]: [boolean, any] = useState(false);
    let [captchaVerified, setCaptchaVerified]: [boolean, any] = useState(false);
    let [isSending, setIsSending]: [boolean, any] = useState(false);
    let [emailSent, setEmailSent]: [boolean, any] = useState(false);
    let [emailError, setEmailError]: [boolean, any] = useState(false);
    let [showPopUp, setShowPopUp]: [boolean, any] = useState(true);

    const checkCaptchaVerification = (e: any) => {
        e.preventDefault();
        if(captchaVerified)
            sendEmail()
        else
            setCaptchaPopUpOpen(true)
    }

    function sendEmail() {
        setCaptchaPopUpOpen(false)
        setCaptchaVerified(true)
        setShowPopUp(true)
        setIsSending(true)
        BackendService.sendEmail(name, email, message).then((res) => {
            setIsSending(false)
            if(res.status == 200)
                setEmailSent(true)
            else
                setEmailError(true)
        }).catch(() => {
            setIsSending(false)
            setEmailError(true)
        });
    }

    return (
        <div id={props.id}>
            <section id="form">
                <div
                    className="section bg-color"
                    style={{
                        backgroundImage:
                            "linear-gradient(to bottom, #FFF -50%, #F2A65A 20%, #b06820 45%, #FFF 50%)"
                    }}
                >
                    <div className="container">
                        <div className="row mx-auto dotted-custom-bg" style={{ maxWidth: 740 }}>
                            <div className="col-md-12">
                                <div className="text-center dark mb-4">
                                    <EditComponentOverlay placeholder={"contact_us_text"} component={
                                        <div>
                                            {parse(props.texts["contact_us_text"].text)}
                                        </div>
                                    }></EditComponentOverlay>
                                </div>
                                <div className="card bg-white shadow-lg border-0">
                                    <div className="card-body p-5">
                                        <Form
                                            className="row mb-0"
                                            onSubmit={checkCaptchaVerification}
                                        >
                                            <div className="col-12 form-group mb-4" style={{textAlign: "left"}}>
                                                <label>Meno:</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    className="form-control form-control-lg required"
                                                    placeholder="Vaše meno"
                                                    required
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 form-group mb-4" style={{textAlign: "left"}}>
                                                <label>Email:</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    className="form-control form-control-lg required"
                                                    placeholder="Váš email"
                                                    required
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 form-group mb-4" style={{textAlign: "left"}}>
                                                <label>Správa:</label>
                                                <textarea
                                                    name="message"
                                                    id="message"
                                                    className="form-control form-control-lg"
                                                    cols={30}
                                                    rows={5}
                                                    placeholder="Vaša správa pre nás..."
                                                    required
                                                    onChange={(e) => setMessage(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <Button
                                                    type="submit"
                                                    className="btn w-100 text-white  rounded-3 py-3 fw-semibold text-uppercase mt-2"
                                                    variant="success"
                                                    disabled={isSending || emailSent}
                                                >
                                                    {emailSent ? "Odoslané :)" : isSending ? "Odosiela sa..." : "Odoslať"}
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CaptchaPopUp show={captchaPopUpOpen && !captchaVerified} onHide={() => setCaptchaPopUpOpen(false)} onChange={sendEmail}/>
            <PopUp TitleText={"Správa odoslaná"} BodyText={"Vaša správa nám bola úspešne odoslaná. Ďakujeme."} show={emailSent && showPopUp} onHide={() => setShowPopUp(false)}></PopUp>
            <PopUp TitleText={"Nastala chyba"} BodyText={"Pri odosielaní Vašej správy nastala nečakaná chyba. Skúste opakovať akciu. Ďakujeme."} show={emailError && showPopUp} onHide={() => setShowPopUp(false)}></PopUp>
        </div>
    );
}

export default ContactComponent;