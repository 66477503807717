import React from "react";
import {ImageItems, MediaLinks, TextItems} from "../../../models/FrontendModels";
import Utils from "../../BackendService/Utils";
import EditComponentOverlay from "../../Overlay/EditComponentOverlay";
import parse from "html-react-parser";

const ShopComponent = (props: { id: string, mediaLinks: MediaLinks, texts: TextItems, images: ImageItems }) => {
    return (
        <div id={props.id}>
            <section id="content">
                <div className="content-wrap pb-0">
                    <div className="container">
                        <div className="row justify-content-between bottommargin-sm">
                            <div className="col-lg-4 col-md-12 col-sm-12 mb-2" style={{textAlign: "left"}}>
                                <EditComponentOverlay placeholder={"about_us"} component={
                                    <div>
                                        {parse(Utils.replaceParagraphs(props.texts["about_us"].text))}
                                    </div>
                                }></EditComponentOverlay>
                                <div className="mt-3">
                                    <a
                                        href={props.mediaLinks["facebook"].link}
                                        target={"_blank"}
                                        className="social-icon si-colored si-delicious mb-0"
                                        title="Facebook"
                                    >
                                        <i className="icon-facebook"/>
                                        <i className="icon-facebook"/>
                                    </a>
                                    <a
                                        href={props.mediaLinks["instagram"].link}
                                        target={"_blank"}
                                        className="social-icon si-colored si-instagramCustomized mb-0"
                                        title="Instagram"
                                    >
                                        <i className="icon-instagram"/>
                                        <i className="icon-instagram"/>
                                    </a>
                                    <a
                                        href={props.mediaLinks["maps"].link}
                                        target={"_blank"}
                                        className="social-icon si-colored si-googleMapsCustomized mb-0"
                                        title="Google Maps"
                                    >
                                        <i className="icon-map-marker2"/>
                                        <i className="icon-map-marker2"/>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12 col-sm-12 mt-4 mt-md-4 mt-sm-5">
                                <EditComponentOverlay placeholder={"shop"} imageComponent={true} placement={"top"} component={
                                    <div>
                                        <img src={Utils.completePathToImage(props.images["shop"].image)}
                                             alt="Shop Image"/>
                                    </div>
                                }></EditComponentOverlay>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ShopComponent;