import React, {useEffect} from "react";
import {Button, Form} from "react-bootstrap";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import BackendService from "../../BackendService/BackendService";
import Utils from "../../BackendService/Utils";
import PopUp from "../../PopUp/PopUp";

const LoginComponent = () => {
    let navigate = useNavigate();
    let [unsuccessfulLogin, setUnsuccessfulLogin] : [boolean, any] = useState(false);

    useEffect(() => {
        if(Utils.UserIsLogged())
            navigate("/admin")
    }, [])

    const handleSubmit = (e: any) => {
        setUnsuccessfulLogin(false)
        e.preventDefault();
        console.log("FORM POST: username = " + userName + ", password = " + password);
        BackendService.login(userName, password).then((res) => {
            if(res.data != ""){
                console.log(res.data)
                localStorage.setItem("loggedIn", "true")
                navigate("/admin")
            }
            else {
                setUnsuccessfulLogin(true)
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    const [userName, setUserName]: [string, any] = useState("");
    const [password, setPassword]: [string, any] = useState("");

    return (
        <div className={"container-fluid"} style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '105vh'}}>
            <Form onSubmit={handleSubmit}>
                <h1 style={{display: 'flex', justifyContent:'center'}}>Prihlásenie sa</h1>
                <Form.Group className="mb-3 mt-2" controlId="formBasicEmail">
                    <Form.Label>Používateľské meno</Form.Label>
                    <Form.Control autoFocus required type="text" placeholder="Zadajte používateľské meno"  onChange={(event) => setUserName(event.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3 mt-2" controlId="formBasicPassword">
                    <Form.Label>Heslo</Form.Label>
                    <Form.Control required type="password" placeholder="Zadajte heslo" onChange={(event) => setPassword(event.target.value)} />
                    <Form.Text className="text-muted">
                        V prípade zabudnutia hesla kontaktujte administrátora
                    </Form.Text>
                </Form.Group>
                <div className={"row mt-2"} style={{display: 'flex', justifyContent:'center'}}>
                    <Button style={{width: 200}} type={"submit"} variant={"success"}>Prihlásiť sa</Button>
                </div>
            </Form>
            <PopUp TitleText={"Prihlasovanie zlyhalo"} BodyText={"Zlé prihlasovacie údaje."} show={unsuccessfulLogin} onHide={() => setUnsuccessfulLogin(false)}/>
        </div>
    )
}

export default LoginComponent;