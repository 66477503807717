import React, {useEffect, useState} from "react";
import EditComponentOverlay from "../../../Overlay/EditComponentOverlay";
import parse from 'html-react-parser';
import BackendService from "../../../BackendService/BackendService";
import {AxiosError} from "axios";
import { Button } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import Utils from "../../../BackendService/Utils";
import {Settings} from "../../../../models/FrontendModels";

const HomeSliderItemComponent = (props: { settings: Settings }) => {
    let [imageName, setImageName]: [string, any] = useState("")
    let [headlineText, setHeadlineText]: [string, any] = useState("")
    let [subHeadlineText, setSubHeadlineText]: [string, any] = useState("")
    useEffect(() => {
        BackendService.getImageByPlaceholder("title").then((res) => {
            setImageName(res.data)
        }).catch((err: AxiosError) => {
            console.log(err)
        }).then(() => {
            BackendService.getTextByPlaceholder("headline_text").then((res) => {
                setHeadlineText(res.data)
            }).catch((err: AxiosError) => {
                console.log(err)
            }).finally(() => {
                BackendService.getTextByPlaceholder("sub_headline_text").then((res) => {
                    setSubHeadlineText(res.data)
                }).catch((err: AxiosError) => {
                    console.log(err)
                })
            })
        })
    })

    return (
        <div className="swiper-slide dark">
            <div className="container">
                <div className="slider-caption slider-caption-center">
                    {(Object.keys(props.settings).includes("show_homepage_info") && !props.settings["show_homepage_info"].boolValue) ? <></> :
                        <EditComponentOverlay placeholder={"title"} imageComponent={true} disableBorderOnHover={true} placement={"top"} component={
                            <div>
                                <EditComponentOverlay placeholder={"headline_text"} component={
                                    <div data-animate="fadeInUp">
                                        {parse(headlineText)}
                                    </div>
                                }></EditComponentOverlay>
                                <EditComponentOverlay placeholder={"sub_headline_text"} component={
                                    <div className="d-none d-sm-block" data-animate="fadeInUp" data-delay="750">
                                        {parse(subHeadlineText)}
                                    </div>
                                }></EditComponentOverlay>
                                <div className="mt-4 row justify-content-center" data-animate="fadeInUp"
                                     data-delay="1500">
                                    <div className="col-2">
                                        <HashLink to="/#predajna">
                                            <Button style={{width: "120px"}} variant={"outline-light"}
                                                    size={"lg"}><strong>Predajňa</strong></Button>
                                        </HashLink>
                                    </div>
                                    <div className="col-2">
                                        <HashLink to="/#produkty">
                                            <Button style={{width: "120px"}} variant={"outline-light"}
                                                    size={"lg"}><strong>Produkty</strong></Button>
                                        </HashLink>
                                    </div>
                                </div>
                            </div>
                        }></EditComponentOverlay>}
                </div>
            </div>
            <div className="swiper-slide-bg"
                 style={{backgroundImage: "url('" + Utils.completePathToImage(imageName) + "')"}}/>
        </div>
    );
}

export default HomeSliderItemComponent;