import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import Utils from "./BackendService/Utils";

const CarouselComponent = (props: { imageUrls: string[], activeIndex: number, onUpdateIndex: (index: number) => void }) => {
    const [index, setIndex] = useState(props.activeIndex); // Set initial state with the activeIndex prop

    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex); // Update state when carousel index changes
        props.onUpdateIndex(selectedIndex); // Call parent function to update carousel index
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect} interval={3000} style={{ height: 600, objectFit: "fill" }}>
            {
                props.imageUrls.map((imgUrl, idx) => (
                    <Carousel.Item key={idx}>
                        <img
                            style={{ objectFit: "cover", width: "100%", height: 600 }}
                            src={Utils.completePathToImage(imgUrl)}
                            alt={`fotka produktu ${idx}`}
                        />
                    </Carousel.Item>
                ))
            }
        </Carousel>
    );
};

export default CarouselComponent;