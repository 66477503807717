import React from "react";
import parse from 'html-react-parser';
import EditComponentOverlay from "../../Overlay/EditComponentOverlay";
import {ImageItems, TextItems} from "../../../models/FrontendModels";
import Utils from "../../BackendService/Utils";

const ServicesComponent = (props: {id: string, texts: TextItems, images: ImageItems  }) => {
    return (
        <div id={props.id}>
            <div className="section bg-light mb-0 mt-1" style={{ padding: "100px 0" }}>
                <div className="container">
                    <div
                        className="heading-block text-center mx-auto"
                        style={{ maxWidth: 700, marginBottom: "1%"}}
                    >
                        <EditComponentOverlay placeholder={"services_headline"} component={
                            <div className="mb-3">
                                {parse(props.texts["services_headline"].text)}
                            </div>
                        }></EditComponentOverlay>
                        <EditComponentOverlay placeholder={"services"} component={
                            <div>
                                {parse(props.texts["services"].text)}
                            </div>
                        }></EditComponentOverlay>
                    </div>
                    <div className="row align-items-stretch justify-content-center" style={{marginBottom: "-2%"}}>
                        {
                            [1, 2, 3, 4].map(serviceNumber => (
                                <div className="col-lg-3 col-md-6 col-sm-6 mt-4">
                                    <div className="card border-0 shadow-sm h-100">
                                        <div className="card-body p-5">
                                            <div className="feature-box flex-column fbox-light fbox-plain" style={{textAlign: "left"}}>
                                                <div className="fbox-icon mb-4">
                                                    <EditComponentOverlay placeholder={"services_icon_" + serviceNumber} imageComponent={true} component={
                                                        <div>
                                                            <img src={Utils.completePathToImage(props.images["services_icon_" + serviceNumber].image)}
                                                                 alt={"Services icon " + serviceNumber}/>
                                                        </div>
                                                    }></EditComponentOverlay>
                                                </div>
                                                <div className="fbox-content">
                                                    <EditComponentOverlay placeholder={"services_card_"  + serviceNumber + "_headline"} component={
                                                        <div className="mb-2">
                                                            {parse(props.texts["services_card_" + serviceNumber + "_headline"].text.replaceAll("<p>", "<a>").replaceAll("</p>", "</a>"))}
                                                        </div>
                                                    }></EditComponentOverlay>
                                                    <EditComponentOverlay placeholder={"services_card_" + serviceNumber + "_text"} component={
                                                        <div>
                                                            {parse(props.texts["services_card_" + serviceNumber + "_text"].text)}
                                                        </div>
                                                    }></EditComponentOverlay>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesComponent;