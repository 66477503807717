import {Button, Modal} from "react-bootstrap";
import {ButtonVariant} from "react-bootstrap/types";

// Component used to deliver custom message to user via popUp
const PopUpComponent = (props : { TitleText: string, BodyText: string, show: boolean, onHide: any, click?: any, buttonVariant?: ButtonVariant}) => {
    return (
        <Modal
            size="lg"
            centered
            {...props}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.TitleText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <a>{props.BodyText}</a>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={props.buttonVariant != undefined ? props.buttonVariant : "success"} onClick={ props.click != undefined ? props.click : props.onHide }>Pokračovať</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PopUpComponent;