import React from "react";
import {ImageItems, TextItems} from "../../../../models/FrontendModels";
import Utils from "../../../BackendService/Utils";
import ProductCategoryItem from "./ProductCategoryItem";
import {Link} from "react-router-dom";

const ProductCategoriesComponent = (props: {id: string, texts: TextItems, images: ImageItems }) => {
    return (
        <div id={props.id} className="container mt-4">
            <div className="row justify-content-center">
                {
                    [1, 2, 3, 4, 5, 6, 7].map((categoryId) => (
                        <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 col-3 mb-3">
                            <Link to={"/kategoria/"+categoryId}>
                                <ProductCategoryItem imageSrc={Utils.completePathToImage(props.images["category_overview_item_"+categoryId].image)} text={props.texts["category_overview_item_"+categoryId].text} placeholder={"category_overview_item_"+categoryId}/>
                            </Link>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default ProductCategoriesComponent;