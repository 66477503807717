import React, {useEffect} from "react";
import {Button, Form} from "react-bootstrap";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import BackendService from "../BackendService/BackendService";
import Utils from "../BackendService/Utils";
import PopUp from "../PopUp/PopUp";
import {MediaLinks} from "../../models/FrontendModels";
import {AxiosError} from "axios";

const LinksEditorComponent = () => {
    let navigate = useNavigate();
    let [mediaLinks, setMediaLinks] : [MediaLinks, any] = useState({});
    let [facebookLink, setFacebookLink] : [string, any] = useState("");
    let [instagramLink, setInstagramLink] : [string, any] = useState("");
    let [googleMapsLink, setGoogleMapsLink] : [string, any] = useState("");
    let [email, setEmail] : [string, any] = useState("");
    let [isSending, setIsSending] : [boolean, any] = useState(false);
    let [isUpdated, setIsUpdated] : [boolean, any] = useState(false);
    let [updateError, setUpdateError]: [boolean, any] = useState(false);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setIsSending(true)
        BackendService.updateMediaLinks(facebookLink, instagramLink, googleMapsLink, email).then(() => {
            setIsUpdated(true)
        }).catch((err : AxiosError) => {
            setUpdateError(true)
            setIsSending(false)
            console.log(err)
        })
    }

    useEffect(() => {
        if(!Utils.UserIsLogged()){
            navigate("/401")
        }
        else{
            BackendService.getMediaLinks().then((res) => {
                setMediaLinks(res.data)
            }).catch((err : AxiosError) => {
                console.log(err)
            })
        }
    }, [])

    useEffect(() => {
        if(Object.keys(mediaLinks).length !== 0 ){
            setFacebookLink(mediaLinks["facebook"].link)
            setInstagramLink(mediaLinks["instagram"].link)
            setGoogleMapsLink(mediaLinks["maps"].link)
            setEmail(mediaLinks["email"].link)
        }
    }, [mediaLinks])

    if(!Utils.UserIsLogged()){
        return(
            <div/>
        )
    }
    else{
        return (
            <div className={"container-fluid"} style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '105vh'}}>
                <Form onSubmit={handleSubmit}>
                    <h1>Upraviť linky pre médiá</h1>
                    <Form.Group className="mb-2 mt-4" controlId="formBasicEmail">
                        <Form.Label>Facebook</Form.Label>
                        <Form.Control autoFocus required type="url" placeholder="Facebook link" value={facebookLink} onChange={(event) => setFacebookLink(event.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-2 mt-2" controlId="formBasicEmail">
                        <Form.Label>Instagram</Form.Label>
                        <Form.Control autoFocus required type="url" placeholder="Instagram link" value={instagramLink} onChange={(event) => setInstagramLink(event.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-2 mt-2" controlId="formBasicEmail">
                        <Form.Label>Google maps</Form.Label>
                        <Form.Control autoFocus required type="url" placeholder="Google maps link" value={googleMapsLink} onChange={(event) => setGoogleMapsLink(event.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-4 mt-2" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control autoFocus required type="email" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)}/>
                    </Form.Group>
                    <div className={"row mt-2"} style={{display: 'flex', justifyContent:'center'}}>
                        <Button style={{width: 100}} variant={"outline-danger"} onClick={() => navigate("/admin")}>Zrušiť</Button>
                        <Button style={{width: 100, marginLeft: 25}} type={"submit"} disabled={isSending || isUpdated} variant={"success"}>{isSending ? "Ukladám" : "Uložiť"}</Button>
                    </div>
                </Form>
                <PopUp TitleText={"Upravené :)"} BodyText={"Nastavenia boli úspěsne upravené."} show={isUpdated} onHide={() => navigate("/admin")}></PopUp>
                <PopUp TitleText={"Nastala chyba"} BodyText={"Nastala nečakaná chyba. Skúste opakovať akciu znovu. Pri opakovanej chybe kontaktuje správcu webu."} show={updateError} onHide={() => setUpdateError(false)}></PopUp>
            </div>
        )
    }
}

export default LinksEditorComponent;