import React from "react";

const ImageUploadComponent = (props: { onChangeAction: React.ChangeEventHandler<HTMLInputElement> | undefined }) => {
    return (
        <div className={"form-group"}>
            <label style={{display: 'flex', justifyContent: 'start'}}>Zvolťe obrázok:</label>
            <input required={true} type={"file"} accept={".png,.jpg,.jpeg,.bmp,.webp"} className={"form-control mt-3"}
                   onChange={props.onChangeAction}></input>
            <div style={{ color: 'orangered', marginTop: '', display: 'flex', justifyContent: 'start'}}>Povolené formáty:&nbsp;<i>png</i>,&nbsp;<i>jpg</i>,&nbsp;<i>jpeg</i>,&nbsp;<i>bmp</i>,&nbsp;<i>webp</i></div>
        </div>
    );
};

export default ImageUploadComponent;