import NavigationComponent from "../navigation/Navigation";
import Footer from "../footer/Footer";
import React, {useEffect, useState} from "react";
import BackendService from "../BackendService/BackendService";
import {AxiosError} from "axios";
import {BlogData, BlogImages} from "../../models/FrontendModels";
import './Blogs.css';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Carousel, Container, Form} from "react-bootstrap";
import PopUp from "../PopUp/PopUp";
import Utils from "../BackendService/Utils";
import parse from "html-react-parser";
import EditComponentOverlay from "../Overlay/EditComponentOverlay";
import CarouselComponent from "../Carousel";

const BlogViewComponent = () => {
    let [blog, setBlog] : [BlogData, any] = useState({id: "", text: "", description: "", title: "", createdAt: ""});
    let [images, setImages] : [BlogImages, any] = useState({});
    let [blogLoaded, setBlogLoaded] : [boolean, any] = useState(false);
    let [deleteBlogPopUpOpen, setDeleteBlogPopUpOpen] : [boolean, any] = useState(false);
    const { blogId } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        if(blog.id != ""){
            setBlogLoaded(true)
        }
    }, [blog])

    useEffect(() => {
        let id = Number(blogId)
        if(isNaN(id)) {
            navigate("/404")
        }
        else {
            BackendService.getBlogPost(id).then((res) => {
                if(res.data[id] == undefined){
                    navigate("/404")
                }
                else {
                    setBlog(res.data[id])
                    BackendService.getBlogImagesByBlogId(id).then((res2) => {
                        console.log(res2.data)
                        setImages(res2.data)
                    }).catch((err: AxiosError) => {
                        console.log(err)
                    })
                }
            }).catch((err: AxiosError) => {
                console.log(err)
            })
        }
    }, [])

    function deleteBlogPost(){
        BackendService.deleteBlogPost(blogId!).then((res) => {
            if(res.data == "OK"){
                navigate("/blog")
            }
            console.log("BLOG DELETE ERROR")
        }).catch((err: AxiosError) => {
            console.log(err)
        })
    }

    if (!blogLoaded){
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
                <div style={{width: '50%'}}>
                    <h2>Načítavam</h2>
                </div>
            </Container>
        )
    } else {
        return (
            <div>
                <NavigationComponent/>
                <div className="section bg-light mb-0 mt-4" style={{padding: "100px 0"}}>
                    <div className="container mx-auto">
                        {blog.id != "-1" ? <div className="row justify-content-center p-0">
                                <div className={"heading-block text-center mx-auto"}>
                                    <h1>{blog.title}</h1>
                                    {Utils.UserIsLogged() &&
                                        <div className={"row justify-content-center"}
                                             style={{marginBottom: "-50px", marginTop: "-25px"}}>
                                            <div className={"col-6"}>
                                                <Button className={"mx-2"}
                                                        onClick={() => navigate("/blog/edit/" + blogId)}>Upraviť</Button>
                                                <Button className={"mx-2"} variant={"warning"}
                                                        onClick={() => navigate("/blog/edit/images/" + blogId)}>Obrázky</Button>
                                                <Button className={"ms-2"} variant={"danger"}
                                                        onClick={() => setDeleteBlogPopUpOpen(true)}>Zmazať</Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className={"text-start"}>
                                    {parse(blog.text)}
                                </div>
                            </div> :
                            <h1>Načítavam...</h1>
                        }
                    </div>
                    {
                        Object.keys(images).length > 0 &&
                        <div style={{padding: "1%"}}>
                            <Carousel interval={3000} style={{height: 600, objectFit: "fill"}} indicators={Object.keys(images).length > 1}>
                                {
                                    Object.keys(images).map((index) => (
                                        <Carousel.Item>
                                            <img
                                                style={{objectFit: "fill", width: "100%", height: 600}}
                                                src={Utils.completePathToImage(images[Number(index)].image)}
                                                alt={"Blog image"}
                                            />
                                        </Carousel.Item>
                                    ))
                                }
                            </Carousel>
                        </div>
                    }
                </div>
                <Footer/>
                <PopUp TitleText={"Zmazať blog"} BodyText={"Ste si naozaj istý, že chcete zmazať tento blog?"}
                       show={deleteBlogPopUpOpen} onHide={() => setDeleteBlogPopUpOpen(false)}
                       click={() => deleteBlogPost()} buttonVariant={"danger"}></PopUp>
            </div>
        );
    }
}

export default BlogViewComponent;