import React from "react";
import BackToHomePageButton from "../Buttons/BackToHomePageButton";
import Footer from "../footer/Footer";
import NavigationComponent from "../navigation/Navigation";

const UnauthorizedComponent = () => {
    return (
        <div>
            <NavigationComponent/>
            <section id="content" className={"mt-6"}>
                <div className="content-wrap">
                    <div className="container">
                        <div className="row align-items-center col-mb-120"  >
                            <div className="col-lg-6 align-self-center">
                                <div className="error404 text-center">401</div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                                <div className="heading-block text-center text-lg-start border-bottom-0">
                                    <h4>Na navštívenie tejto stránky nemáte práva.</h4>
                                    <span>Skúste opakovať akciu, alebo sa presmerujte na úvodnú stránku.</span>
                                    <BackToHomePageButton/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    );
}

export default UnauthorizedComponent;