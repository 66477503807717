import {Modal} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import BackendService from "../BackendService/BackendService";

const CaptchaPopUp = (props : any) => {
    return (
        <Modal {...props} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Ešte jeden krok...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>Musíme sa uistiť, že nie ste robot.</h6>
                <ReCAPTCHA sitekey={BackendService.config.captchaSiteKey} onChange={props.onChange}></ReCAPTCHA>
            </Modal.Body>
        </Modal>
    )
}

export default CaptchaPopUp;