import React from "react";
import EditComponentOverlay from "../../Overlay/EditComponentOverlay";
import parse from 'html-react-parser';
import {ImageItems, TextItems} from "../../../models/FrontendModels";
import Utils from "../../BackendService/Utils";

const AboutUsComponent = (props: { id: string, texts: TextItems, images: ImageItems }) => {
    return (
        <section id="content">
            <div className="content-wrap" id={props.id}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12" style={{textAlign: "left"}}>
                            <EditComponentOverlay placeholder={"about_us_headline"} component={
                                <div className="mb-3" style={{textAlign: "center"}}>
                                    {parse(props.texts["about_us_headline"].text)}
                                </div>
                            }></EditComponentOverlay>
                            <EditComponentOverlay placeholder={"about_us_text"} component={
                                <div>
                                    {parse(props.texts["about_us_text"].text)}
                                </div>
                            }></EditComponentOverlay>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12mt-5 mt-md-0">
                            <div className="about-img position-relative px-4">
                                <EditComponentOverlay placeholder={"about_us"} imageComponent={true} placement={"top"} component={
                                    <div>
                                        <img src={Utils.completePathToImage(props.images["about_us"].image)}
                                             alt="About Us Image"/>
                                    </div>
                                }></EditComponentOverlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutUsComponent;