import React from "react";
import {Tilt} from "react-tilt";
import {useNavigate} from "react-router-dom";
import EditComponentOverlay from "../../../Overlay/EditComponentOverlay";
import Utils from "../../../BackendService/Utils";

const ProductCategoryItemComponent = (props: { imageSrc: string, text: string, placeholder: string, alt?: string }) => {
    let navigate = useNavigate();
    const Options = {
        reverse: true,  // reverse the tilt direction
        max: 10,     // max tilt rotation (degrees)
        perspective: 1000,   // Transform perspective, the lower, the more extreme the tilt gets.
        scale: 1.05,    // 2 = 200%, 1.5 = 150%, etc..
        speed: 100,   // Speed of the enter/exit transition
        transition: true,   // Set a transition on enter/exit.
        axis: null,   // What axis should be disabled. Can be X or Y.
        reset: true,    // If the tilt effect has to be reset on exit.
        easing: "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
    }

    return (
        <EditComponentOverlay placeholder={props.placeholder} placement={"top"} imageComponent={true} component={
            <EditComponentOverlay placeholder={props.placeholder} placement={"bottom"} component={
                <div className="category">
                    <Tilt options={Options} style={{height: "250px"}}>
                        <img className={"categoryImage"} src={props.imageSrc} alt={props.alt ? props.alt : "obrázok"}/>
                        <h2 className={"categoryText"}>
                            {Utils.removeTagsFromRichString(props.text)}
                        </h2>
                    </Tilt>
                </div>
            }></EditComponentOverlay>
        }></EditComponentOverlay>
    );
}

export default ProductCategoryItemComponent;