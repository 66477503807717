export class Utils {
    public static UserIsLogged(): boolean {
        return !!localStorage.getItem("loggedIn");
    }

    public static replaceParagraphs(text: string): string {
        return text.replaceAll("<p>","<a>").replaceAll("</p>","</a></br>")
    }
    
    public static removeTagsFromRichString(text: string): string {
        if(!text)
            return "";
        while(text.includes(">") && text.includes("<"))
            text = text.substring(text.indexOf(">")+1, text.lastIndexOf("<"))
        if(text.includes("&"))
            text = text.replaceAll("&amp;", "&")
        return text
    }

    public static completePathToImage(imageName: string): string {
        return "https://mlbaby.sk/img/" + imageName
        //return "http://localhost/ml_baby_images/" + imageName // LOCAL PATH
    }

    public static isTouchScreenDevice(): boolean {
        try{
            document.createEvent('TouchEvent');
            return true;
        }catch(e){
            return false;
        }
    }

    public static determinateDatabaseCategoryId(categoryId : string | number | undefined) {
        let categoryIdInt = Number(categoryId)
        if (categoryIdInt > 0 && categoryIdInt < 8 && categoryId != undefined) {
            return categoryIdInt + 2
        }
        else {
            return -1
        }
    }

    public static validateFileType = (file: any) => {
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'image/webp'];
        return allowedTypes.includes(file.type);
    };
}

export default Utils