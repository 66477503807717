import React, {ChangeEvent, useEffect, useState} from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import {useNavigate, useParams} from "react-router-dom";
import BackendService from "../BackendService/BackendService";
import {AxiosError} from "axios";
import {BlogData} from "../../models/FrontendModels";
import Utils from "../BackendService/Utils";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const BLogEditCreateComponent = (props: { isEditAction: boolean }) => {
    let navigate = useNavigate();
    const [files, setFiles] = useState<File[]>([]);
    let [blog, setBlog] : [BlogData, any] = useState({id: "", text: "", description: "", title: "", createdAt: ""});
    let [blogLoaded, setBlogLoaded] : [boolean, any] = useState(false);
    let [isEditAction, setIsEditAction] : [boolean, any] = useState(true);
    const { blogId } = useParams();

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if(Utils.UserIsLogged()){
            if(isEditAction) {
                BackendService.editBlogPost(Number(blogId), blog.title, blog.description, blog.text).then((res) => {
                    navigate("/blog/"+blogId)
                }).catch((err: AxiosError) => {
                    console.log(err)
                })
            }
            else{
                BackendService.createBlogPost(blog.title, blog.description, blog.text).then((res) => {
                    uploadImages(String(res.data))
                    navigate("/blog")
                }).catch((err: AxiosError) => {
                    console.log(err)
                })
            }
        }
    };

    const uploadImages = async (id: string) => {
        const uploadPromises = files.map(async (file) => {
            try {
                const res = await BackendService.uploadBlogImage(id, file);
                console.log(res.data)
                if (res.data !== "OK") {
                    console.log(res.data);
                }
            } catch (err) {
                console.error(err);
            }
        });

        // Wait for all upload promises to resolve
        await Promise.all(uploadPromises);
    }

    useEffect(() => {
        if (props.isEditAction) {
            setIsEditAction(true)
            let id = Number(blogId)
            if(isNaN(id)) {
                navigate("/404")
            }
            else {
                BackendService.getBlogPost(id).then((res) => {
                    if(res.data[id] == undefined){
                        navigate("/404")
                    }
                    else {
                        setBlog(res.data[id])
                        setBlogLoaded(true)
                    }
                }).catch((err: AxiosError) => {
                    console.log(err)
                })
            }
        }
        else {
            setIsEditAction(false)
            setBlogLoaded(true)
        }
    }, [props.isEditAction])

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const selectedFiles: File[] = Array.from(e.target.files); // Convert FileList to an array of File
            setFiles(selectedFiles); // Set the state with the array of selected files
        }
    };

    useEffect(() => {
        if (!Utils.UserIsLogged()) {
            navigate("/401")
        }
    }, [])

    if (!blogLoaded){
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
                <div style={{width: '50%'}}>
                    <h2>Načítavam</h2>
                </div>
            </Container>
        )
    } else {
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Form style={{ width: '75%' }} onSubmit={handleSubmit}>
                    <h2>{isEditAction ? "Úprava blogu" : "Tvorba blogu"}</h2>
                    <Form.Group controlId="blogTitle">
                        <Form.Label>Názov</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Zadajte názov"
                            value={blog.title}
                            onChange={(e) => setBlog({...blog, title: e.target.value})}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="blogDescription" className={"mt-3"}>
                        <Form.Label>Popis</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Zadajte popis"
                            value={blog.description}
                            onChange={(e) => setBlog({...blog, description: e.target.value})}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="blogText" className={"mt-3"}>
                        <Form.Label>Text</Form.Label>
                        <div style={{maxHeight: "250px", overflowY: "auto"}}>
                            <CKEditor
                                editor={ClassicEditor}
                                data={blog.text}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setBlog({...blog, text: data})
                                }}
                            />
                        </div>
                        <p style={{position: "absolute", color: "gray", fontSize: "12px"}}>Prosím nepoužívajte znaky &#34;&lt;&#34;, &#34;&gt;&#34; a &#34;&#38;&#34;.</p>
                    </Form.Group>

                    {!isEditAction &&
                        <Form.Group controlId="blogText" className={"mt-3"}>
                            <Form.Label>Zvoľte obrázky:</Form.Label>
                                    <label style={{ display: 'flex', justifyContent: 'start' }}>Zvoľte obrázky:</label>
                                    <input
                                        required={true}
                                        type={"file"}
                                        accept={"image/*"}
                                        className={"form-control"}
                                        onChange={handleFileChange}
                                        multiple
                                    />
                        </Form.Group>
                    }

                    <div>
                        <Button variant="secondary" onClick={() => navigate("/blog")} style={{ opacity: 0.80 }}>
                            Zahodiť
                        </Button>
                        <Button variant="success" type="submit" className={"m-3"}>
                            Uložiť
                        </Button>
                    </div>
                </Form>
            </Container>
        );
    }
};

export default BLogEditCreateComponent;