import React, {useEffect, useState} from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Button} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import BackendService from "../BackendService/BackendService";
import Utils from '../BackendService/Utils';
import { AxiosError } from 'axios';
import PopUp from "../PopUp/PopUp";

const TextEditorComponent = () => {
    const { placeholder, encodedReturnUrl } = useParams();
    let [text, setText] : [string, any] = useState("");
    let [isLoading, setIsLoading] : [boolean, any] = useState(true);
    let [isSending, setIsSending] : [boolean, any] = useState(false);
    let [showPopUp, setShowPopUp]: [boolean, any] = useState(false);
    let [returnUrl, setReturnUrl]: [string, any] = useState("");
    let navigate = useNavigate();

    useEffect(() => {
        if(!Utils.UserIsLogged()){
            navigate("/401")
        }
        else if(placeholder == undefined){
            navigate("/404")
        }
        else {
            BackendService.getTextByPlaceholder(placeholder!).then((res) => {
                setIsLoading(false)
                setText(res.data)
            }).catch((err: AxiosError) => {
                if(err.response!.status == 404){
                    setText("Pole s daným placeholderom sa nenašlo v databáze")
                }
                else{
                    setText("Nastala nečakaná chyba")
                }
                setIsLoading(false)
                console.log(err)
            })
        }
        if(encodedReturnUrl == undefined)
            setReturnUrl("/")
        else
            setReturnUrl(atob(encodedReturnUrl))
    }, [])

    function saveChanges() {
        setIsSending(true)
        BackendService.updateText(placeholder!, text).then(() => {
            setShowPopUp(true)
        }).catch((err) => {
            console.log(err)
            setIsSending(false)
        })
    }

    if(isLoading){
        return(
            <div/>
        )
    }
    return (
        <div>
            <div className={"row justify-content-center mt-2"}>
                <div className={"col-6"}>
                    <h2>Text editor</h2>
                    <CKEditor
                        editor={ClassicEditor}
                        data={text}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setText(data)
                        }}
                    />
                    <p style={{position: "absolute", color: "gray", fontSize: "12px"}}>Prosím nepoužívajte znaky &#34;&lt;&#34;, &#34;&gt;&#34; a &#34;&#38;&#34;.</p>
                </div>
            </div>
            {/*<div className={"mt-3"}>
                <h2>Content</h2>
                {text}
            </div>*/}
            <div className={"mt-4"}>
                <Button variant={"outline-danger"} onClick={() => navigate(returnUrl)}>Zahodiť</Button>
                <Button variant={"success"} onClick={saveChanges} disabled={isSending} style={{marginLeft: "10px"}}>{isSending ? "Ukladám" : "Uložiť"}</Button>
            </div>
            <PopUp TitleText={"Text zmenený"} BodyText={"text bol úspešne zmenený."} show={showPopUp} onHide={() => navigate(returnUrl)}></PopUp>
        </div>
    );
}

export default TextEditorComponent;