import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import BackendService from "../BackendService/BackendService";

const AdminComponent = () => {
    let navigate = useNavigate();
    let [userLoaded, setUserLoaded] : [boolean, any] = useState(false)

    useEffect(() => {
        BackendService.userCheck().catch((err) => {
            console.log(err)
        })
        const userIsLogged = localStorage.getItem("loggedIn")
        if(!userIsLogged)
            navigate("/login")
        else
            setUserLoaded(true)
    }, []);

    function logOut() {
        BackendService.logout().then((res) => {
            localStorage.removeItem("loggedIn")
            navigate("/")
        }).catch((err) => {
            console.log(err)
        })
    }

    if(userLoaded)
        return (
            <div className={"container-fluid"}>
                <div className={"row mt-3"}>
                    <h1 style={{display: 'flex', justifyContent:'center'}}>Úspešne prihlásený</h1>
                </div>
                <div className={"row justify-content-center mt-3"}>
                    <Button variant={"success"} style={{width: 200}} onClick={() => navigate("/")}>Domovská stránka</Button>
                    <Button variant={"primary"} style={{width: 200, marginLeft: 25}} onClick={() => navigate("/linksedit")}>Upraviť linky</Button>
                    <Button variant={"secondary"} style={{width: 200, marginLeft: 25}} onClick={() => navigate("/settings")}>Nastavenia</Button>
                    <Button variant={"danger"} style={{width: 200, marginLeft: 25}} onClick={logOut}>Odhlásiť sa</Button>
                </div>
            </div>
        )
    else
        return(
            <div/>
        )
}

export default AdminComponent;