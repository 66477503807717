import React, { createContext, useState } from 'react';
import './style-canvas.css';
import './css-canvas/bootstrap.css';
import './css-canvas/swiper.css';
import './css-canvas/dark.css';
import './css-canvas/font-icons.css';
import './css-canvas/animate.css';
import './css-canvas/magnific-popup.css';
import './App.css';
import HomeComponent from "./components/home/Home";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import NotFoundComponent from "./components/ErrorPages/NotFound";
import LoginComponent from "./components/Admin/Login/Login";
import UnauthorizedComponent from "./components/ErrorPages/Unauthorized";
import InternalServerError from "./components/ErrorPages/InternalServerError";
import AdminComponent from "./components/Admin/Admin";
import TextEditorComponent from "./components/Admin/TextEditor";
import LinksEditorComponent from "./components/Admin/LinksEditor";
import ImageEditorComponent from "./components/Admin/ImageEditor";
import CategoryViewComponent from "./components/CategoryView/CategoryView";
import ImageUploaderComponent from "./components/Admin/ImageUploader";
import SettingsComponent from "./components/Admin/Settings";
import SiteNotCompletedComponent from "./components/ErrorPages/SiteNotCompleted";
import CategoryImagesEditComponent from "./components/Admin/CategoryImagesEdit";
import BlogsViewComponent from "./components/Blogs/BlogsView";
import { Constants } from './models/Constants';
import DocumentMeta from 'react-document-meta';
import BlogViewComponent from "./components/Blogs/BlogView";
import BlogCreation from "./components/Blogs/BlogEditCreate";
import BLogEditCreateComponent from "./components/Blogs/BlogEditCreate";
import BlogImagesEditorComponent from "./components/Blogs/BlogImagesEditor";

export let MetaContext: any = createContext([Constants.defaultMeta, () => {return;}])

function App() {
    let [meta, setMeta] = useState(Constants.defaultMeta);
    const metaState = {meta, setMeta};

    return (
        <div className="App">
            <div id="wrapper" className="clearfix">
                <DocumentMeta {...meta} />
                <MetaContext.Provider value={metaState}>
                <BrowserRouter>
                    <Routes>
                        <Route path={"/"} element={<HomeComponent/>} />
                        <Route path={"/login"} element={<LoginComponent/>}></Route>
                        <Route path={"/admin"} element={<AdminComponent/>}></Route>
                        <Route path={"/blog"} element={<BlogsViewComponent/>}></Route>
                        <Route path={"/blog/:blogId"} element={<BlogViewComponent/>}></Route>
                        <Route path={"/blog/edit/:blogId"} element={<BLogEditCreateComponent isEditAction={true}/>}></Route>
                        <Route path={"/blog/edit/images/:blogId"} element={<BlogImagesEditorComponent/>}></Route>
                        <Route path={"/blog/create"} element={<BLogEditCreateComponent isEditAction={false}/>}></Route>
                        <Route path={"/textedit/:placeholder/:encodedReturnUrl"} element={<TextEditorComponent/>}></Route>
                        <Route path={"/imageedit/:placeholder/:encodedReturnUrl"} element={<ImageEditorComponent/>}></Route>
                        <Route path={"/imageedit/:placeholder"} element={<ImageEditorComponent/>}></Route>
                        <Route path={"/imageupload/:categoryId/:encodedReturnUrl"} element={<ImageUploaderComponent/>}></Route>
                        <Route path={"/categoryimagesedit/:categoryId/:encodedReturnUrl"} element={<CategoryImagesEditComponent/>}></Route>
                        <Route path={"/linksedit"} element={<LinksEditorComponent/>}></Route>
                        <Route path={"/settings"} element={<SettingsComponent/>}></Route>
                        <Route path={"/kategoria/:categoryId"} element={<CategoryViewComponent/>}></Route>
                        <Route path={"/401"} element={<UnauthorizedComponent/>}></Route>
                        <Route path={"/404"} element={<NotFoundComponent/>}></Route>
                        <Route path={"/500"} element={<InternalServerError/>}></Route>
                        <Route path={"*"} element={<NotFoundComponent/>} ></Route>
                    </Routes>
                </BrowserRouter>
                </MetaContext.Provider>
            </div>
        <div id="gotoTop" className="icon-angle-up"></div>
    </div>
  );
}

export default App;
