import NavigationComponent from "../navigation/Navigation";
import Footer from "../footer/Footer";
import React, {useEffect, useState} from "react";
import BackendService from "../BackendService/BackendService";
import {AxiosError} from "axios/index";
import {BlogsBasicData} from "../../models/FrontendModels";
import './Blogs.css';
import {useNavigate} from "react-router-dom";
import Utils from "../BackendService/Utils";
import {Button} from "react-bootstrap";

const BlogsViewComponent = () => {
    let navigate = useNavigate();
    let [blogs, setBlogs] : [BlogsBasicData, any] = useState({});

    useEffect(() => {
        BackendService.getAllBlogs().then((res) => {
            setBlogs(res.data)
        }).catch((err: AxiosError) => {
            console.log(err)
        })
    }, [])

    return (
        <div>
            <NavigationComponent/>
            <div className="section bg-light mb-0 mt-4" style={{ padding: "100px 0" }}>
                <div className="container mx-auto">
                    <div className={"heading-block text-center mx-auto"}>
                        <h2 className={"mt-2"}>Blog</h2>
                        {Utils.UserIsLogged() &&
                            <div className={"row justify-content-center"} style={{marginBottom: "-50px", marginTop: "-25px"}}>
                                <div className={"col-3"}>
                                    <Button className={"mx-2"} variant={"success"} onClick={() => navigate("/blog/create")}>Vytvoriť</Button>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row justify-content-center p-0" >
                        {
                            blogs && Object.keys(blogs).length > 0 ? (
                            Object.keys(blogs).reverse().map(key => (
                                <div className="col-lg-4 col-md-6 col-sm-12 item" onClick={() => navigate("/blog/" + blogs[key].id)}>
                                    <div className="item-in" style={{ textAlign: 'left' }}>
                                        <h4>{blogs[key].title}</h4>
                                        <div className="seperator"></div>
                                        <p>{blogs[key].description}</p>
                                        <a className={"link"}>Prečítať</a>&nbsp;<p className={"arrowIcon"}>➜</p>
                                    </div>
                                </div>
                            ))) : <p>Načítavam...</p>
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default BlogsViewComponent;