import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Utils from '../BackendService/Utils';
import {useLocation, useNavigate} from "react-router-dom";
import {Placement} from "react-bootstrap/types";

const EditComponentOverlayTriggerComponent = (props: {placeholder: string, component: any, returnUrl?: any, placement?: Placement, imageComponent?: boolean, disableBorderOnHover?: boolean}) => {
    let navigate = useNavigate();
    let location = useLocation();

    if(!Utils.UserIsLogged()){
        return(
            <>
                {props.component}
            </>
        )
    }
    else{
        return(
            <OverlayTrigger
                placement={(props.placement == null) ? "right" : props.placement}
                delay={{show: 250, hide: 1000 }}
                overlay={
                    <Tooltip id="button-tooltip">
                        {
                            props.imageComponent ?
                                <Button onClick={() => navigate("/imageEdit/" + props.placeholder + "/" + btoa(location.pathname))}>Upraviť obrázok</Button> :
                                <Button onClick={() => navigate("/textEdit/" + props.placeholder + "/" + btoa(location.pathname))}>Upraviť text</Button>
                        }
                    </Tooltip>
                }
            >
                <div className={props.disableBorderOnHover ? "" : "editableText"}>
                    {props.component}
                </div>
            </OverlayTrigger>
        )
    }
}

export default EditComponentOverlayTriggerComponent;
