import React from "react";
import HomeSliderItemComponent from "./homeSliderItem/HomeSliderItemComponent";
import {Settings} from "../../../models/FrontendModels";

const HomeSliderComponent = (props: { settings: Settings }) =>{
    return (
        <section id="slider" className="slider-element slider-parallax swiper_wrapper min-vh-60 min-vh-md-100 include-header slider-parallax-visible">
        <div className="slider-inner">
            <div className="swiper-container swiper-parent">
                <div className="swiper-wrapper">
                    <HomeSliderItemComponent settings={props.settings}/>
                </div>
            </div>
        </div>
    </section>);
}

export default HomeSliderComponent;